.contactsectwobg {
    background: #211f72;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.ContactDetailHeading {
    color: #fff;
    font-size: 1.4rem;
    padding: 1rem;
    font-weight: 600;
    text-align: right;
}
.ContactSectwodetails ul {
    padding: 1rem 0 0.4rem;
    display: block;
}
.ContactSectwodetails ul li {
    padding: 0 0 0 1rem;
    width: 100%;
    color: #fff;
    list-style-type: none;
    text-align: left;
    font-size: 1rem;
}
.ContactSectwodetails ul li:nth-child(2),
.ContactSectwodetails ul li:nth-child(3) {
    width: 50%;
    float: left;
    padding-bottom: 1rem;
}
.ContactLeadfrom.contact-table{
    margin-top: 1rem;
    padding-top: 1rem;
    box-shadow: 0px 0px 10px #f0f0f0;
}
.contact-table thead th {
    color: #000;
    font-size: 1rem;
    text-align: left;
    font-weight: 600;
    border: none !important;
    background: #F3F3F3;
    white-space: nowrap;
}
.contact-table tbody td{
    color: #000;
    font-size: 0.9rem;
    text-align: left;
    border: none;
    border-bottom: 1px solid #dee2e6;
}
.contact-line p{
    font-size: 0.9rem;
    color: #4B4B4B;
    line-height: 1.2rem;
    text-align: left;
}
.contact-line ul li{
    font-size: 0.9rem;
    color: #4B4B4B;
    line-height: 1.2rem;
    text-align: left;
}
.contact-table tbody td a{
    color: #000;
}
.contact-line ul li a{
    color:  #4B4B4B;
    font-weight: 700;
}
.contact-table .ContactLeadformHeading {
    padding: 0 0 1rem;
}
@media (min-width: 768px) and (max-width: 991.5px){
    .contact-table tbody td,.contact-table thead th{
        padding: 0.7rem 0.35rem;
    }
}
@media (min-width: 576px) and (max-width: 767.5px) {
    .ContactDetailHeading {
        text-align: left;
        padding: 0.4rem 1rem;
        font-size: 1.2rem;
    }
    .ContactSectwodetails ul {
        padding: 0.2rem 0 0.4rem;
    }
    .contact-table tbody td,.contact-table thead th{
        padding: 0.7rem 0.35rem;
        white-space: nowrap;
    }
}
@media (min-width: 480px) and (max-width: 575.5px) {
    .ContactDetailHeading {
        text-align: left;
        padding: 0.4rem 1rem;
        font-size: 1.2rem;
    }
    .ContactSectwodetails ul {
        padding: 0.2rem 0 0.4rem;
    }
    .contact-table tbody td,.contact-table thead th{
        padding: 0.7rem 0.35rem;
        white-space: nowrap;
        font-size: 0.9rem;
    }
    .contact-table tbody td{
        font-size: 0.85rem;
    }
}
@media (max-width: 479.5px) {
    .ContactDetailHeading {
        text-align: left;
        padding: 0.4rem 1rem;
        font-size: 1.2rem;
    }
    .ContactSectwodetails ul {
        padding: 0.2rem 0 0.4rem;
    }
    .contact-table tbody td,.contact-table thead th{
        padding: 0.7rem 0.35rem;
        white-space: nowrap;
        font-size: 0.9rem;
    }
    .contact-table tbody td{
        font-size: 0.8rem;
    }
}
